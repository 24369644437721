.post {
  padding: 10px 15px !important;
  margin: 10px 0;
}

.post-header {
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
  margin: 0 !important;
}

.title {
  padding: 0 !important;
  margin: 0 !important;
}

.timestamp {
    color: gray;
}

#post-form {
    position: relative;
}

#username-field #content-field {
    margin: 10px;
}

.MuiFormControl-root {
    width: 100%;
    margin: 10px 0 !important;
}
